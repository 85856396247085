import React from "react"
import ScrollAnimation from "react-animate-on-scroll"

const HeroSection = () => {
  return ( 
    <div className="about-hero">
      <div className="about-hero-content">
        <p className="section-subtitle">ABOUT US</p>
        <h1 className="hero-heading">
          Committed to keeping SMEs safe
        </h1>
        <p className="sw-text">
          With cyber threats continuously evolving and becoming more complex, 
          no business should leave their cybersecurity to chance.  
          Securiwiser provides businesses with a 24/7 cybersecurity monitoring tool 
          tailored to the specific needs of SMEs. 
        </p>
      </div>
    </div>
  )
}

export default HeroSection
