import React from "react"
import { ReadMore } from "../helperComponents/helpers.js"
import ScrollAnimation from "react-animate-on-scroll"
import "./style/index.scss"
import Vid from "../videoPlayer/videoPlayer.js"

const AboutSection = () => {
  return (    
    <div className="about-section">
      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <h2 className="sw-heading-1">OUR MISSION</h2>
      </ScrollAnimation>
      <div className="about-text">
        <p>
          For us, this project began in an attempt to tackle an increasingly prominent issue  
          within the cybersecurity landscape – the inaccessibility of cyber security services for, 
          small and medium-sized enterprises (SMEs). We believe that all businesses, big and small, 
          deserve the opportunity to empower and protect themselves against the looming threats of 
          increasing cybercrime. Our mission is simple – to revolutionise the cyber-landscape by 
          increasing the accessibility of top-of-the-range tools to detect and monitor your cybersecurity 
          risks and vulnerabilities before they are exploited by hackers.
        </p>
        <div>
          <p>
            Securiwiser has been curated with a focus on simplicity, affordability and accessibility.  
            With hundreds of monitors continuously scanning for vulnerabilities, Securiwiser can identify 
            and report cyberthreats, thus keeping you and your organization ahead of the hackers.  
          </p>
          <p> 
            We want to empower businesses and organisations to navigate the digital world safely by giving them 
            the tools and knowledge they need to understand cybersecurity risks and keep their businesses safe.
          </p>
        </div>
      </div>
      <div className="about-media">
        <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <div>
          <h2 className="sw-heading-1">
          Cybersecurity made simple
          </h2>
          <p className="sw-text">
          Stay on top of your cybersecurity with easy-to-understand, concise daily reports and suggested solutions. 
          </p>
          <ReadMore url="https://app.securiwiser.com/signup">
            Get Started
          </ReadMore>
        </div>
        </ScrollAnimation>
        <div>      
        <Vid src="Ee396A2xr-0" provider="youtube"/>
        </div>
      </div>
    </div>
  )
}

export default AboutSection
