import React from "react"
import HeroSection from "./heroSection.js"
import AboutSection from "./aboutSection.js"
import GlobalPandemic from "../cyberStats/globalPandemic.js"
import Cta from "../cta/cta.js"
import "./style/index.scss"

const Index = () => {
  return (
    <div>
      <HeroSection />
      <AboutSection />
      <GlobalPandemic />
      <Cta />
    </div>
  )
}

export default Index
